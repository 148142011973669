@import '../../../../scss/theme-bootstrap';

.footer-links {
  &__title {
    @include title--h5;
    font-family: $font--lars-bold;
    color: $color-black;
    @include breakpoint($landscape-up) {
      margin-bottom: 30px;
      padding-inline-start: 4px;
    }
  }
  &__link {
    margin-bottom: 20px;
    @include breakpoint($landscape-up) {
      margin-bottom: 19px;
    }
    a,
    span {
      color: $color-black;
      font-size: 14px;
      text-decoration: none;
      @include breakpoint($landscape-up) {
        font-size: 12px;
      }
    }
  }
  &__content {
    .footer-links__title {
      cursor: pointer;
      @include breakpoint($landscape-up) {
        cursor: default;
        pointer-events: none;
      }
    }
    .footer-links__mobile-content-toggle {
      display: inline-block;
      float: $rdirection;
      height: 20px;
      width: 20px;
      transform: rotate(90deg);
      transition: backgroound 0.3s ease-in;
      @include breakpoint($landscape-up) {
        display: none;
      }
    }
    &[aria-expanded='true'] {
      .footer-links__mobile-content-toggle {
        transform: rotate(0deg);
      }
    }
    .footer-links__section {
      height: auto;
      margin-top: 16px;
      transition: height 0.3s ease-in;
      &[aria-hidden='true'] {
        height: 0;
        overflow: hidden;
        @include breakpoint($landscape-up) {
          height: auto;
          padding-inline-start: 4px;
        }
      }
    }
  }
}
