///
/// @file global/_mixins.scss
///
/// \brief Mixins are helpful snippets that let us not repeat ourselves
///
///
/// Font Smoothing
/// Primarily used to improve rendering of light text on dark background
///
@mixin font-smoothing($value: true) {
  @if ($value == true) {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  } @else {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}

///
/// Style input placeholders
///
@mixin input-placeholder {
  &.placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
    opacity: 1 !important;
  } //opacity needed to override FF user agent
  &::-moz-placeholder {
    @content;
    opacity: 1 !important;
  }
  &:-ms-input-placeholder {
    @content;
  }
  &::-webkit-input-placeholder {
    @content;
  }
}

///
/// Style input checkbox and input radio element
///
@mixin input-types($padding: 0px) {
  // hide the actual element
  position: absolute;
  #{$ldirection}: -9999px;
  ~ label,
  ~ .label {
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.1px;
    padding: $padding 0 $padding 40px;
    position: relative;
  }
  &:disabled ~ label,
  &:disabled ~ .label {
    color: $color-primary-500;
  }
}

@mixin input-checkbox($padding: 5px) {
  @include input-types($padding);
  ~ label,
  ~ .label {
    &::before {
      background: $color-white;
      border: 2px solid $color-primary-900;
      border-radius: 2px;
      content: ' ';
      cursor: pointer;
      display: inline-block;
      position: absolute;
      top: 2px;
      #{$ldirection}: 0;
      height: 18px;
      width: 18px;
    }
  }
  &:checked ~ label,
  &:checked ~ .label {
    &::before {
      background: $color-primary-900;
    }
    &::after {
      border-#{$rdirection}: 2px solid $color-white;
      border-bottom: 2px solid $color-white;
      content: ' ';
      display: inline-block;
      position: absolute;
      top: 3px;
      #{$ldirection}: 5px;
      transform: rotate(45deg);
      height: 13px;
      width: 7px;
    }
  }
  &:disabled ~ label,
  &:disabled ~ .label {
    &::before {
      background: $color-white;
      border-color: $color-primary-500;
    }
  }
  &:disabled:checked ~ label,
  &:disabled:checked ~ .label {
    &::before {
      background: $color-primary-500;
    }
  }
}

@mixin input-radio($padding: 5px) {
  @include input-types($padding);
  + label,
  + .label {
    display: block;
    color: $black;
    &::before {
      background: $color-white;
      border: 2px solid $color-primary-900;
      border-radius: 50%;
      content: ' ';
      cursor: pointer;
      display: block;
      position: absolute;
      top: 2px;
      #{$ldirection}: 0;
      height: 18px;
      width: 18px;
    }
  }
  &:checked + label,
  &:checked + .label {
    &::before {
      background: $color-white;
    }
    &::after {
      background: $color-primary-900;
      border-radius: 50%;
      content: ' ';
      cursor: pointer;
      display: inline-block;
      position: absolute;
      top: 7px;
      #{$ldirection}: 5px;
      height: 8px;
      width: 8px;
    }
  }
  &:disabled + label,
  &:disabled + .label {
    &::before {
      background: $color-white;
      border-color: $color-primary-500;
    }
  }
  &:disabled:checked + label,
  &:disabled:checked + .label {
    &::after {
      background: $color-primary-500;
    }
  }
}

///
/// Center Element on any Axis
/// https://css-tricks.com/snippets/sass/centering-mixin/them if it's important.
// NOTE: assumes parent element has position: relative;
@mixin centerer($horizontal: true, $vertical: true) {
  position: absolute;
  @if ($horizontal and $vertical) {
    @include transform(translate(-50%, -50%));
    top: 50%;
    #{$ldirection}: 50%;
  } @else if ($horizontal) {
    @include transform(translate(-50%, 0));
    #{$ldirection}: 50%;
  } @else if ($vertical) {
    @include transform(translate(0, -50%));
    top: 50%;
  }
}

@mixin destroy-centerer() {
  @include transform(none);
  position: static;
  top: auto;
  #{$ldirection}: auto;
}

@mixin swap_direction($type, $v) {
  @if length($v) == 4 {
    @if $text-direction == rtl {
      #{$type}: nth($v, 1) nth($v, 4) nth($v, 3) nth($v, 2);
    } @else {
      #{$type}: $v;
    }
  } @else {
    #{$type}: $v;
  }
}

///
/// Triangles - creates right triangles with border-clipping tricks.
///
/// Adapted from this: http://codepen.io/vieron/pen/xuHDA
///
/// Usage:
/// @include triangle(size, color, direction)
/// @include bordered-triangle(size, color, direction, border-color, [border-width], [cut])
///
/// size: The length of the hypotenuse of the triangle
/// color: The fill color of the triangle
/// direction: Which way the arrow points
///
/// border-color: The color of the border around the triangle
/// border-width: The width of the border around the triangle
/// cut: Removes the border along the hypotenuse
///
@mixin triangle($size, $color, $direction) {
  height: 0;
  width: 0;
  border-color: transparent;
  border-style: solid;
  border-width: $size / 2;
  @if $direction == up {
    border-bottom-color: $color;
  } @else if $direction == right {
    border-left-color: $color;
  } @else if $direction == down {
    border-top-color: $color;
  } @else if $direction == left {
    border-right-color: $color;
  }
}

// Typography
@mixin title--h1 {
  font-size: 24px;
  font-family: $font--malone;
  letter-spacing: get-letter-spacing(10);
  line-height: get-line-height(24px, 32px);
  @include breakpoint($landscape-up) {
    font-size: 32px;
    line-height: get-line-height(32px, 40px);
  }
}

@mixin title--h2 {
  font-size: 24px;
  font-family: $font--malone;
  letter-spacing: get-letter-spacing(10);
  line-height: get-line-height(24px, 32px);
}

@mixin title--h3 {
  font-size: 20px;
  font-family: $font--malone;
  letter-spacing: 0;
  line-height: get-line-height(20px, 28px);
}

@mixin title--h4 {
  font-size: 16px;
  font-family: $font--malone;
  letter-spacing: 0;
  line-height: get-line-height(16px, 24px);
}

@mixin title--h4-secondary {
  font-size: 18px;
  font-family: $font--malone;
  letter-spacing: get-letter-spacing(10);
  line-height: get-line-height(16px, 24px);
}

@mixin title--h5 {
  font-size: 12px;
  font-family: $font--lars;
  font-weight: 700;
  letter-spacing: 0;
  text-transform: uppercase;
  line-height: get-line-height(12px, 18px);
}

@mixin title--h5-secondary {
  font-size: 16px;
  font-family: $font--lars;
  letter-spacing: get-letter-spacing(10);
  line-height: get-line-height(12px, 20px);
}

@mixin title--h5-secondary-style1 {
  font-size: 14px;
  font-family: $font--lars;
  font-weight: 700;
  letter-spacing: get-letter-spacing(120);
  text-transform: uppercase;
  line-height: get-line-height(12px, 18px);
}

@mixin title--h6 {
  font-size: 12px;
  font-family: $font--lars;
  letter-spacing: get-letter-spacing(170);
  line-height: get-line-height(12px, 18px);
  text-transform: uppercase;
}

@mixin elc-order-tracking-margin {
  margin-top: $elc_order_tracking_margin_value;
}

@mixin body-text {
  font-family: $font--lars;
  font-size: 14px;
  letter-spacing: get-letter-spacing(40);
  line-height: get-line-height(10px, 16px);
}

@mixin body-text2 {
  font-family: $font--lars;
  font-size: 13px;
  letter-spacing: get-letter-spacing(40);
  line-height: get-line-height(10px, 16px);
}

@mixin body-text3 {
  font-family: $font--lars;
  font-size: 12px;
  letter-spacing: get-letter-spacing(40);
  line-height: get-line-height(10px, 16px);
}

@mixin engrave-block {
  font-family: $font--bernhard-bold;
  text-transform: uppercase;
}

@mixin engrave-script {
  font-family: $font--kuenstler;
}

@mixin spp-title-and-label {
  color: $black;
  font-family: $font--malone;
  letter-spacing: unset;
}

@mixin spp-title-text {
  font-size: 24px;
  line-height: 1.3;
  text-transform: capitalize;
}

@mixin spp-description {
  @include body-text2;
  color: $black;
  font-weight: 400;
  line-height: 1.6;
  text-decoration: none;
}

// Buttons
@mixin button {
  font-family: $font--lars;
  font-size: 14px;
  padding: 0 32px;
  height: 50px;
  letter-spacing: get-letter-spacing(10);
  font-weight: 400;
  line-height: 48px;
  text-align: center;
  display: inline-block;
  border: none;
  text-decoration: none;
  background: none;
  cursor: pointer;
  &:focus-visible {
    // Use important to override some existing outline none in the templates, libs like slick.
    outline: 2px solid !important;
    outline-offset: -6px;
  }
}

@mixin button--large {
  width: 300px;
  @include breakpoint($landscape-up) {
    width: 326px;
  }
  &:focus-visible {
    // Use important to override some existing outline none in the templates, libs like slick.
    outline: 2px solid !important;
    outline-offset: -6px;
  }
}
@mixin button--large-disabled {
  @include button--large;
  color: $white;
  background: $color-cream-disabled;
}

@mixin button--disabled {
  @include button;
  background: $color-cream-disabled;
  color: $white;
  min-width: 300px;
  @include breakpoint($landscape-up) {
    min-width: 260px;
  }
}

@mixin button--dark {
  @include button;
  color: $color-cream;
  background: $black;
  &:hover {
    color: $color-cream;
    text-decoration: none;
    box-shadow: none;
  }
}

@mixin button--light {
  @include button;
  color: $black;
  box-shadow: inset 0 0 0 1px $black;
  letter-spacing: 0;
  &:hover {
    text-decoration: underline;
  }
}

@mixin button--secondary {
  @include button;
  @include button--light;
  &:hover {
    text-decoration: none;
  }
}

@mixin button--white {
  @include button;
  color: $black;
  background: $white;
  box-shadow: inset 0 0 0 1px $black;
}

@mixin button--text {
  @include body-text;
  letter-spacing: get-letter-spacing(20);
  margin: 0;
  padding: 0;
  background: inherit;
  color: $black;
  height: 20px;
  box-shadow: 0 1px 0 $black;
  &:hover {
    color: $black;
    background: inherit;
    box-shadow: none;
  }
  &:focus-visible {
    outline: 1px dotted $black;
  }
}

@mixin button--dark-secondary {
  @include button--dark;
  min-width: 300px;
  @include breakpoint($landscape-up) {
    min-width: 260px;
  }
}

@mixin button--transparent {
  @include button;
  background: transparent;
  border: 1px solid $white;
  color: $white;
}

@mixin button--auto-width {
  @include button;
  width: fit-content;
  height: 40px;
  padding: 0 24px;
  border: 1px solid $color-charleston-green;
  display: flex;
  align-items: center;
  pointer-events: none;
  color: $color-cream;
  background: $color-charleston-green;
}

@mixin button--auto-width-border-radius {
  @include button;
  width: fit-content;
  height: 40px;
  pointer-events: none;
  padding: 0 24px;
  border-radius: 10px;
  border: 1px solid $color-charleston-green;
  color: $color-cream;
  display: flex;
  align-items: center;
  background: $color-charleston-green;
}

@mixin button-small {
  align-items: center;
  border: 1px solid $color-charleston-green;
  background-color: $color-charleston-green;
  color: $color-cream;
  cursor: pointer;
  display: inline-flex;
  font-family: $font--lars;
  font-size: 14px;
  font-weight: 400;
  height: 52px;
  justify-content: center;
  letter-spacing: get-letter-spacing(10);
  padding: 16px 24px;
  text-align: center;
  text-decoration: none;
  &:hover {
    color: $color-cream;
    text-decoration: none;
  }
  &:focus-visible {
    // Use important to override some existing outline none in the templates, libs like slick.
    outline: 2px solid !important;
    outline-offset: -6px;
  }
}

@mixin button-small--border-radius {
  @include button-small;
  border-radius: 10px;
}

@mixin cta-pill-stroke {
  @include button--secondary;
  border-radius: 24px;
  font-size: 16px;
  font-family: $font--malone;
  height: 48px;
  padding: 0 30px;
  &:focus-visible {
    outline: 2px solid !important;
    outline-offset: -6px;
  }
}

@mixin cta-pill-stroke--dark {
  @include cta-pill-stroke;
  background: $black;
  color: $white;
}

@mixin cta-pill-stroke--white {
  @include cta-pill-stroke;
  background: $white;
  box-shadow: 0 1px 0 $white;
}

@mixin cta-rouded-stroke--blue {
  @include button--light;
  background: $color-blue-darkest;
  display: block;
  box-shadow: unset;
  border-radius: 10px;
  color: $white;
  width: 280px;
  height: 52px;
  &:hover {
    color: $white;
    text-decoration: none;
  }
}

@mixin control-tab-deselected {
  @include cta-pill-stroke;
  box-shadow: unset;
  border: 1px solid $color-grey-light;
}

@mixin cta-pill-stroke--selected {
  @include cta-pill-stroke;
  font-family: $font--lars;
  font-size: 13px;
  height: 44px;
}

@mixin cta-pill-stroke--deselected {
  @include cta-pill-stroke--selected;
  box-shadow: unset;
  border: 1px solid $color-grey-light;
}

@mixin remove-filter-text {
  @include title--h6;
  text-transform: none;
}

// Links
@mixin link-underline($color: $color-black) {
  box-shadow: 0 1px 0 0 $color;
  text-decoration: none;
}

@mixin link--base {
  font-family: $font--lars;
  color: $black;
  display: inline-block;
  font-size: 12px;
  letter-spacing: get-letter-spacing(170);
  line-height: 16px; // Intentionally px based for the box shadow
  text-decoration: none;
  cursor: pointer;
  &:hover,
  &:active {
    @include link-underline;
    text-decoration: none;
  }
}

@mixin link {
  @include link--base;
  font-size: 14px;
  letter-spacing: get-letter-spacing(0);
}

@mixin link--min {
  @include link--base;
  @include link-underline;
  box-shadow: 0 1px 0 0 $color-black;
  font-size: 14px;
  letter-spacing: get-letter-spacing(0);
}

@mixin link--caps {
  @include link--base;
  @include link-underline;
  box-shadow: 0 1px 0 0 $color-black;
  text-transform: uppercase;
}

@mixin link-focused-outline {
  &:focus-visible {
    outline: 2px solid !important;
    outline-offset: 1px;
  }
}

///
/// Fluid Images for responsive designs
///

@mixin fluid-image {
  display: block;
  height: auto;
  max-width: 100%;
  width: 100%;
}
/* SelectBox Dropdown control */
@mixin selectBox {
  font-size: $base-fontsize;
  text-decoration: none;
  text-align: left;
  min-width: 150px;
  position: relative;
  border: 1px solid $color-lighter-gray;
  border-radius: 0;
  color: $color-dark-gray;
  outline: none;
  vertical-align: middle;
  display: inline-block;
  height: 40px;
  line-height: 37px;
  background: $color-white;
  cursor: pointer;
}
/* SelectBox Options */
@mixin selectBox-options {
  font-size: $base-fontsize;
  text-decoration: none;
  text-align: left;
  list-style: none;
  display: block;
  padding: 10px 0;
  margin: 0;
  overflow: auto;
  cursor: pointer;
}

// Container related width rules
@mixin container-max-width-full {
  margin-inline: auto;
  max-width: 100%;
  width: 100%;
}

@mixin container-max-width-landscape {
  margin-inline: auto;
  max-width: $container-max-width-landscape;
  width: 100%;
}

@mixin container-max-width-landscape-indented {
  margin-inline: auto;
  max-width: $container-max-width-landscape - 98px;
  width: 100%;
}

@mixin ternary($condition, $property, $true-value, $false-value) {
  @if ($condition) {
    #{$property}: $true-value;
  } @else {
    #{$property}: $false-value;
  }
}

@mixin reset-box {
  margin: 0;
  padding: 0;
}

// Container related vertical rules
@mixin container-vertical-rules($dir: top, $prop: margin) {
  .container-vertical-#{$dir}-#{$prop} {
    &--16 {
      #{$prop}-#{$dir}: 16px;
    }
    &--24 {
      #{$prop}-#{$dir}: 24px;
    }
    &--32 {
      #{$prop}-#{$dir}: 32px;
    }
    &--40 {
      #{$prop}-#{$dir}: 40px;
    }
    &--50 {
      #{$prop}-#{$dir}: 50px;
    }
    &--80 {
      #{$prop}-#{$dir}: 80px;
    }
    &--100 {
      #{$prop}-#{$dir}: 100px;
    }
    &--32-40 {
      #{$prop}-#{$dir}: 32px;
      @include breakpoint($landscape-up) {
        #{$prop}-#{$dir}: 40px;
      }
    }
    &--48-56 {
      #{$prop}-#{$dir}: 48px;
      @include breakpoint($landscape-up) {
        #{$prop}-#{$dir}: 56px;
      }
    }
    &--56-112 {
      #{$prop}-#{$dir}: 56px;
      @include breakpoint($landscape-up) {
        #{$prop}-#{$dir}: 112px;
      }
    }
    &--60-25 {
      #{$prop}-#{$dir}: 60px;
      @include breakpoint($landscape-up) {
        #{$prop}-#{$dir}: 25px;
      }
    }
    &--60-40 {
      #{$prop}-#{$dir}: 60px;
      @include breakpoint($landscape-up) {
        #{$prop}-#{$dir}: 40px;
      }
    }
    &--64-80,
    &--small {
      // @todo remove --small when blogs have been updated
      #{$prop}-#{$dir}: 64px;
      @include breakpoint($landscape-up) {
        #{$prop}-#{$dir}: 80px;
      }
    }
    &--80-112 {
      #{$prop}-#{$dir}: 80px;
      @include breakpoint($landscape-up) {
        #{$prop}-#{$dir}: 112px;
      }
    }
    &--96-184 {
      #{$prop}-#{$dir}: 96px;
      @include breakpoint($landscape-up) {
        #{$prop}-#{$dir}: 184px;
      }
    }
    &--0-150 {
      #{$prop}-#{$dir}: 0;
      @include breakpoint($landscape-up) {
        #{$prop}-#{$dir}: 150px;
      }
    }
    &--100-150 {
      #{$prop}-#{$dir}: 100px;
      @include breakpoint($landscape-up) {
        #{$prop}-#{$dir}: 150px;
      }
    }
    &--160-224 {
      #{$prop}-#{$dir}: 160px;
      @include breakpoint($landscape-up) {
        #{$prop}-#{$dir}: 224px;
      }
    }
    &--large {
      // @todo remove --large when blogs have been updated
      #{$prop}-#{$dir}: 80px;
      @include breakpoint($landscape-up) {
        #{$prop}-#{$dir}: 112px;
      }
    }
  }
}

@mixin invert-color($background-color, $intensity) {
  background-color: $background-color;
  @supports (-webkit-backdrop-filter: invert($intensity)) {
    -webkit-backdrop-filter: invert($intensity);
    background-color: transparent;
  }
  @supports (backdrop-filter: invert($intensity)) {
    backdrop-filter: invert($intensity);
    background-color: transparent;
  }
}

@mixin unset-invert-color($background-color) {
  background-color: $background-color;
  @supports (-webkit-backdrop-filter: invert(0)) {
    -webkit-backdrop-filter: unset;
  }
  @supports (backdrop-filter: invert(0)) {
    backdrop-filter: unset;
  }
}

// Loaders
@mixin loader($color: $black) {
  $loader-pos: 9999px;
  $loader-dot-spacing: 24px;
  $loader-dot1: $loader-pos - $loader-dot-spacing;
  $loader-dot2: $loader-pos;
  $loader-dot3: $loader-pos + $loader-dot-spacing;
  width: 3px;
  height: 3px;
  border-radius: 3px;
  background-color: $color;
  color: $color;
  display: none;
  position: relative;
  #{$rdirection}: $loader-pos;
  margin: auto;
  animation: dot-pulse 1.5s infinite linear;
  animation-delay: 0.5s;
  animation-timing-function: step-end;
  margin-top: 40px;
  &::before,
  &::after {
    width: 3px;
    height: 3px;
    border-radius: 3px;
    background-color: $color;
    color: $color;
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }
  &::before {
    animation: dot-pulse-before 1.5s infinite linear;
    animation-delay: 0s;
    animation-timing-function: step-end;
  }
  &::after {
    animation: dot-pulse-after 1.5s infinite linear;
    animation-delay: 1s;
    animation-timing-function: step-end;
  }
  @keyframes dot-pulse-before {
    0% {
      box-shadow: $loader-dot1 0 0 0;
    }
    30% {
      box-shadow: $loader-dot1 0 0 1px;
    }
    60%,
    100% {
      box-shadow: $loader-dot1 0 0 0;
    }
  }
  @keyframes dot-pulse {
    0% {
      box-shadow: $loader-dot2 0 0 0;
    }
    30% {
      box-shadow: $loader-dot2 0 0 1px;
    }
    60%,
    100% {
      box-shadow: $loader-dot2 0 0 0;
    }
  }
  @keyframes dot-pulse-after {
    0% {
      box-shadow: $loader-dot3 0 0 0;
    }
    30% {
      box-shadow: $loader-dot3 0 0 1px;
    }
    60%,
    100% {
      box-shadow: $loader-dot3 0 0 0;
    }
  }
}
// flex align // align-self / align-items
@mixin flex-align-swap-direction($type, $flex-align) {
  @if ($flex-align == flex-start) {
    @if ($text-direction == rtl) {
      #{$type}: flex-end;
    } @else {
      #{$type}: flex-start;
    }
  } @else if ($flex-align == flex-end) {
    @if ($text-direction == rtl) {
      #{$type}: flex-start;
    } @else {
      #{$type}: flex-end;
    }
  }
}
// flex direction
@mixin flex-row-swap-direction() {
  @if ($text-direction == rtl) {
    flex-direction: row-reverse;
  } @else {
    flex-direction: row;
  }
}

/// Slide Animations
///
/// Example Usage: slide-animation('left', 'in') --> slideFadeInFromLeft
/// If no 'fade-direction' is provided, there will be no fade
///
@mixin slide-animation($from-direction: 'left', $fade-direction: '') {
  $keyframe-name: '';
  $from: to-upper-case(str-slice(to-lower-case($from-direction), 1, 1)) +
    str-slice(to-lower-case($from-direction), 2);
  @if ($fade-direction == 'in') {
    $keyframe-name: slideFadeInFrom#{$from};
  } @else if ($fade-direction == 'out') {
    $keyframe-name: slideFadeOutFrom#{$from};
  } @else {
    $keyframe-name: slideFrom#{$from};
  }
  @include keyframes($keyframe-name) {
    0% {
      @include animation-timing-function(ease-out);
      @if ($fade-direction == 'in') {
        opacity: 0;
      } @else if ($fade-direction == 'out') {
        opacity: 1;
      }
      @if ($from == 'Top') {
        transform: translate3d(0, -100%, 0);
      } @else if ($from == 'Right') {
        transform: translate3d(100%, 0, 0);
      } @else if ($from == 'Bottom') {
        transform: translate3d(0, 100%, 0);
      } @else {
        transform: translate3d(-100%, 0, 0);
      }
    }
    100% {
      @include animation-timing-function(ease-in);
      @if ($fade-direction == 'in') {
        opacity: 1;
      } @else if ($fade-direction == 'out') {
        opacity: 0;
      }
      transform: translate3d(0, 0, 0);
    }
  }
}

// Mixins for sub-line-as-title, is-tax-include, is-lengthy-engraving-cta, enable-global-font-for-oab-intro-title vars
@mixin sub-line-as-title {
  .elc-product-full {
    .elc-product {
      &-sub-display-name {
        font-family: $font--lars;
        margin: 20px 20px 13px;
        @include breakpoint($medium-up) {
          margin: 20px 0 0;
        }
      }
      &-display-name-wrapper .elc-product-name-wrapper {
        margin: 0;
      }
    }
  }
  .elc-product-quick-view-wrapper {
    .elc-product-sub-display-name {
      font-family: $font--lars;
      margin-top: 20px;
      @include breakpoint($medium-up) {
        margin: 16px 0;
      }
    }
  }
}

@mixin is-tax-include {
  .sd-product-spp {
    .elc-product-full {
      .elc-product {
        &-original-price {
          padding-#{$ldirection}: 5px;
        }
      }
    }
    .elc-size-picker-container {
      .elc-product-tax-included-label-wrapper {
        min-width: 32px;
      }
    }
  }
}

@mixin is-lengthy-engraving-cta {
  .engraving-preview-popup {
    .spp-engraving-preview {
      .spp-engraving-form-content {
        .button {
          &.change-font {
            padding: 0 1px;
            min-width: 120px;
            @include breakpoint($landscape-up) {
              padding: 0 20px;
            }
          }
        }
      }
    }
  }
}

@mixin enable-global-font-for-oab-intro_title {
  .elc-appt-booking_intro-container {
    h1 {
      font-family: $font--malone;
    }
  }
}

@mixin swap_pr_count($copy) {
  @if $text-direction == rtl {
    &::after {
      content: $copy;
    }
  } @else {
    &::before {
      content: $copy;
    }
  }
}

@mixin is-lengthy-mpp-cta {
  .sd-product-grid {
    .elc-accordion {
      a.elc-product-cta {
        height: auto;
        width: auto;
      }
    }
  }
}

@mixin sd_disabled_toos_cta {
  .elc-product-cta-wrapper {
    &.temporarily-out-of-stock {
      .elc-add-to-bag-button {
        pointer-events: none;
        background-color: $color-primary-300;
        color: $color-grey-disabled;
      }
    }
  }
}
@mixin sd_hide_toos_cta {
  .elc-product-cta-wrapper {
    &.temporarily-out-of-stock {
      display: none;
    }
  }
}

@mixin is-engraving-has-choice-8 {
  .engraving-preview-popup {
    .engraving-size-200g {
      &.background-swap {
        &.engraving-form-choice-8 {
          background-image: url('/media/export/cms/engraving/product/926x541/jo_200g_926x541_c.jpg');
          .background-prod-image-inner {
            background-position: center;
            background-size: 118%;
            @include breakpoint($landscape-up) {
              background-size: cover;
            }
          }
          .engrave-placement {
            input {
              font-size: 9.5px;
              @include breakpoint($landscape-up) {
                #{$ldirection}: -28px;
                font-size: 14px;
                top: -7px;
              }
              &.engrave-choice-block {
                font-size: 11px;
                @include breakpoint($landscape-up) {
                  font-size: 14px;
                }
              }
            }
          }
          .close-container {
            background-image: url('/media/export/cms/engraving/custom_nav_x-dark.png');
          }
          .new-canvas {
            @include breakpoint($landscape-down) {
              background-image: url('/media/export/cms/engraving/product/478x426/jo_200g_478x426_c.jpg');
            }
          }
        }
      }
    }
    .engraving-size-200g,
    .engraving-size-600g,
    .engraving-size-2_1kg {
      &.engraving-form-choice-8 {
        .engraving-forms {
          padding-top: 20px;
          @include breakpoint($landscape-up) {
            padding-top: 0;
          }
        }
        .engraving-form-preview .engraving-form-heading {
          padding-bottom: 20px;
          @include breakpoint($landscape-up) {
            padding-bottom: 0;
          }
        }
        .engrave-placement {
          @include breakpoint($landscape-up) {
            #{$rdirection}: 52px;
            top: 465px;
            width: 359px;
          }
          input {
            #{$ldirection}: -5px;
            font-size: 12px;
            font-weight: bold;
            text-shadow: 1px 1px 10px $black;
            top: 186px;
            width: 100%;
            @include breakpoint($landscape-up) {
              font-size: 15px;
              top: 4px;
            }
            &.engrave-choice-block {
              font-size: 13.5px;
              @include breakpoint($landscape-up) {
                font-size: 15px;
              }
            }
          }
        }
      }
    }
    .engraving-size-600g {
      &.background-swap {
        &.engraving-form-choice-8 {
          background-image: url('/media/export/cms/engraving/product/926x541/jo_600g_926x541_c.jpg');
          .background-prod-image {
            background: none !important;
          }
          .new-canvas {
            @include breakpoint($landscape-down) {
              background-image: url('/media/export/cms/engraving/product/478x426/jo_600g_478x426_c.jpg');
            }
          }
          .engrave-placement {
            input {
              top: 202px;
              @include breakpoint($landscape-up) {
                top: 7px;
              }
            }
          }
        }
      }
    }
    .engraving-size-2_5kg {
      &.background-swap {
        &.engraving-form-choice-8 {
          background-image: url('/media/export/cms/engraving/product/926x541/jo_2500g_926x541_c.jpg');
          .close-container {
            background-image: url('/media/export/cms/engraving/custom_nav_x-dark.png');
          }
          .new-canvas {
            @include breakpoint($landscape-down) {
              background-image: url('/media/export/cms/engraving/product/478x426/jo_2500g_478x426_c.jpg');
            }
          }
          .engrave-placement {
            #{$ldirection}: 500px;
            height: 45px;
            top: 465px;
            width: 385px;
          }
        }
      }
      .engraving-form-choice-8 .engrave-placement {
        #preview-3-1,
        #preview-3-1-shadow {
          font-size: 1.8em;
          height: 100%;
          text-shadow: 1px 1px 1px $black;
          width: 100%;
          @include breakpoint($landscape-up) {
            font-size: 15px;
          }
        }
      }
    }
    // cat-CAT11895
    &.engraving-cat-CAT11895 {
      .background-swap.engraving-form-choice-8 {
        background-image: url('/media/export/cms/engraving/product/478x426/jo_200g_CI_478x426_c.jpg');
        @include breakpoint($landscape-up) {
          background-image: url('/media/export/cms/engraving/product/926x541/jo_200g_CI_926x541_c.jpg');
        }
        &.engraving-size-200g {
          background-image: url('/media/export/cms/engraving/product/478x426/jo_200g_CI_478x426_c.jpg');
          @include breakpoint($landscape-up) {
            background-image: url('/media/export/cms/engraving/product/926x541/jo_200g_CI_926x541_c.jpg');
          }
          .close-container {
            background-image: url('/media/export/cms/engraving/custom_nav_x.png');
          }
        }
      }
    }
  }
}

@mixin large-text {
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  background-size: cover;
  -webkit-transition: background-image 1s ease-in-out;
  -moz-transition: background-image 1s ease-in-out;
  -o-transition: background-image 1s ease-in-out;
  transition: background-image 1s ease-in-out;
}

@mixin script-text-1 {
  font-size: 0.6em;
  #{$ldirection}: 0;
  width: 100%;
}

@mixin script-text-2 {
  font-size: 1.5em;
  width: 100%;
  margin-#{$ldirection}: -5px;
}

@mixin preview-1-1 {
  font-size: 2em;
  width: 100%;
}

@mixin preview-1-2 {
  font-size: 3.5em;
  width: 100%;
}

// checkout related mixins

// secondary header/title style
@mixin checkout-secondary-heading {
  font-family: $main-font-regular;
  font-size: get-rem(30px);
  font-weight: 300;
  letter-spacing: 0;
  line-height: get-line-height(30px, 32px);
}

// sub header/title style - medium version
@mixin checkout-sub-heading--medium {
  font-family: $main-font;
  font-size: get-rem(16px);
  font-weight: 300;
  letter-spacing: 0;
  line-height: get-line-height(16px, 24px);
}

// sub header/title style - small version - style 1
@mixin checkout-sub-heading--small {
  font-family: $main-font;
  font-size: get-rem(14px);
  line-height: get-line-height(14px, 18px);
}

// sub header/title style - small version - style 2
@mixin checkout-sub-heading--small-style2 {
  font-family: $bold-font-family;
  font-size: get-rem(14px);
  letter-spacing: 1.7px;
  line-height: get-line-height(14px, 20px);
  text-transform: uppercase;
}

// default styling for paragraphs/body text
@mixin checkout-body-text--medium {
  font-family: $main-font;
  font-size: get-rem(16px);
  letter-spacing: 0;
  line-height: get-line-height(16px, 24px);
}

// default styling for paragraphs/body text bold
@mixin checkout-body-text--bold {
  font-family: $bold-font-family;
  font-size: get-rem(16px);
  letter-spacing: 0;
  line-height: get-line-height(16px, 24px);
}

// default styling for smaller paragraphs/body text
@mixin checkout-body-text--small {
  font-family: $main-font;
  font-size: get-rem(14px);
  line-height: get-line-height(14px, 16px);
}

@mixin onoffswitch_box_margin {
  -moz-transition: margin 0.3s ease-in 0s;
  -webkit-transition: margin 0.3s ease-in 0s;
  -o-transition: margin 0.3s ease-in 0s;
  transition: margin 0.3s ease-in 0s;
}

@mixin onoff_switch_all {
  -moz-transition: all 0.3s ease-in 0s;
  -webkit-transition: all 0.3s ease-in 0s;
  -o-transition: all 0.3s ease-in 0s;
  transition: all 0.3s ease-in 0s;
}

@mixin onoff_user {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

// default styling for smaller paragraphs/text for product size/quantity
@mixin checkout-body-text--small-style2 {
  font-family: $main-font;
  font-size: get-rem(13px);
  line-height: get-line-height(14px, 16px);
  letter-spacing: 0.4px;
}

// default styling for smaller paragraphs/text for product Prize
@mixin checkout-body-text--small-style3 {
  font-family: $main-font;
  font-size: get-rem(12px);
  line-height: get-line-height(14px, 16px);
  letter-spacing: 1.7px;
}

// loop through multiple property options in an element
@mixin loop-prop-options(
  $suffix: 'color',
  $prop: 'background-color',
  $options: ($color-cream, $color-blue, $color-red),
  $modifier: true) {
  @if $modifier {
    @each $name, $option in $options {
      @if $name != '' {
        &#{$suffix}--#{$name} {
          #{$prop}: $option;
        }
      }
    }
  } @else {
    @each $name, $option in $options {
      @if $suffix != '' and $name != '' {
        .#{$suffix}-#{$name} {
          #{$prop}: $option;
        }
      }
    }
  }
}

// Mixin for CSS-based modal fade animation
@mixin modal-fade($timing) {
  animation: $timing 1 forwards modal-fade;
  display: block !important;
  opacity: 1;
  visibility: visible;
  @keyframes modal-fade {
    0% {
      opacity: 1;
    }
    99% {
      opacity: 0;
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100vw);
    }
  }
}
